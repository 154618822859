import gql from 'graphql-tag';

export const DSPIsActionEnabledForContentQuery = gql`
	query DSPIsActionEnabledForContentQuery(
		$action: DataSecurityPolicyAction!
		$contentId: ID!
		$contentStatus: DataSecurityPolicyDecidableContentStatus!
		$contentVersion: Int!
		$spaceKey: String
	) {
		dataSecurityPolicy {
			isActionEnabledForContent(
				action: $action
				contentId: $contentId
				contentStatus: $contentStatus
				contentVersion: $contentVersion
				spaceKey: $spaceKey
			) {
				action
				allowed
			}
		}
	}
`;
