import { defineMessages } from 'react-intl-next';

import { SpotlightLayout } from './spotlightTypes';

export const spotlightExtensionType = 'com.atlassian.confluence.spotlight';
export const spotlightExtensionKey = 'spotlight';

const i18n = defineMessages({
	splitDesignContentFirstLabel: {
		id: 'custom-sites-extensions.spotlight.spotlight-config-panel.layouts.split-design-content-first.label',
		defaultMessage: 'Split design (content first)',
		description: 'Split design layout option label',
	},
	splitDesignContentFirstDescription: {
		id: 'custom-sites-extensions.spotlight.spotlight-config-panel.layouts.split-design-content-first.description',
		defaultMessage: 'Large image on the right with lots of room for content',
		description: 'Split design layout option description',
	},
	splitDesignImageFirstLabel: {
		id: 'custom-sites-extensions.spotlight.spotlight-config-panel.layouts.split-design-image-first.label',
		defaultMessage: 'Split design (image first)',
		description: 'Split design layout option label',
	},
	splitDesignImageFirstDescription: {
		id: 'custom-sites-extensions.spotlight.spotlight-config-panel.layouts.split-design-image-first.description',
		defaultMessage: 'Large image on the left with lots of room for content',
		description: 'Split design layout option description',
	},
	solidColorBackgroundLabel: {
		id: 'custom-sites-extensions.spotlight.spotlight-config-panel.layouts.solid-color-background.label',
		defaultMessage: 'Solid color background',
		description: 'Solid color background layout option label',
	},
	solidColorBackgroundDescription: {
		id: 'custom-sites-extensions.spotlight.spotlight-config-panel.layouts.solid-color-background.description',
		defaultMessage: 'Content in front of a full-width color',
		description: 'Solid color background layout option description',
	},
	// We will eventually use these translations when full background layout is properly introduced
	imageBackgroundLabel: {
		id: 'custom-sites-extensions.spotlight.spotlight-config-panel.layouts.image-background.label',
		defaultMessage: 'Image background',
		description: 'Image background layout option label',
	},
	imageBackgroundDescription: {
		id: 'custom-sites-extensions.spotlight.spotlight-config-panel.layouts.image-background.description',
		defaultMessage: 'Full-size image behind your content',
		description: 'Image background layout option description',
	},
});

export const getLayoutOptions = () => [
	{
		label: i18n.splitDesignContentFirstLabel,
		description: i18n.splitDesignContentFirstDescription,
		value: SpotlightLayout.SPLIT_IMAGE_RIGHT,
	},
	{
		label: i18n.splitDesignImageFirstLabel,
		description: i18n.splitDesignImageFirstDescription,
		value: SpotlightLayout.SPLIT_IMAGE_LEFT,
	},
	{
		label: i18n.solidColorBackgroundLabel,
		description: i18n.solidColorBackgroundDescription,
		value: SpotlightLayout.NO_IMAGE_BACKGROUND,
	},
];
