import { LoadablePaint } from '@confluence/loadable';

export const SearchRenderedExtensionLoader = LoadablePaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-SearchRenderedExtension" */ './SearchRenderedExtension'
			)
		).SearchRenderedExtension,
});
