/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum DataSecurityPolicyAction {
  APP_ACCESS = "APP_ACCESS",
  PAGE_EXPORT = "PAGE_EXPORT",
  PUBLIC_LINKS = "PUBLIC_LINKS",
}

export enum DataSecurityPolicyDecidableContentStatus {
  ARCHIVED = "ARCHIVED",
  CURRENT = "CURRENT",
  DRAFT = "DRAFT",
}

//==============================================================
// END Enums and Input Objects
//==============================================================

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: DSPIsActionEnabledForContentQuery
// ====================================================

export interface DSPIsActionEnabledForContentQuery_dataSecurityPolicy_isActionEnabledForContent {
  action: DataSecurityPolicyAction | null;
  allowed: boolean | null;
}

export interface DSPIsActionEnabledForContentQuery_dataSecurityPolicy {
  isActionEnabledForContent: DSPIsActionEnabledForContentQuery_dataSecurityPolicy_isActionEnabledForContent;
}

export interface DSPIsActionEnabledForContentQuery {
  dataSecurityPolicy: DSPIsActionEnabledForContentQuery_dataSecurityPolicy | null;
}

export interface DSPIsActionEnabledForContentQueryVariables {
  action: DataSecurityPolicyAction;
  contentId: string;
  contentStatus: DataSecurityPolicyDecidableContentStatus;
  contentVersion: number;
  spaceKey?: string | null;
}
