import type { Auth as MediaAuth, AuthProvider, ClientBasedAuth } from '@atlaskit/media-core';

import { mediaConfigEnforceDSPRecorder, mediaConfigRecorder } from '@confluence/ssr-utilities';
import { fg } from '@confluence/feature-gating';

import { createRendererMediaProvider } from './MediaProvider';

type MediaSSR = {
	mode: 'server' | 'client';
	config: {
		authProvider: AuthProvider;
		initialAuth?: MediaAuth;
		enforceDataSecurityPolicy?: boolean;
	};
};

type AuthProviderConfigOptions = {
	contentId?: string;
	spaceKey?: string;
	isExternalShare: boolean;
	mediaToken?: string;
};

const MEDIA_PROVIDER_UNAVAILABLE_ERROR = 'mediaProvider unavailable, must use initialAuth';

// eslint-disable-next-line check-react-ssr-usage/no-react-ssr
const isServerEnvironment = () => process.env.REACT_SSR;

const isClientEnvironment = () => window.__SSR_RENDERED__;

const getInitialAuth = (config: AuthProviderConfigOptions): ClientBasedAuth | undefined => {
	const recordedAuth = mediaConfigRecorder?.get();
	if (!recordedAuth) return undefined;
	return {
		...recordedAuth,
		// If the mediaToken is provided in the config, use it.
		// This ensures the correct token is used in cases where the token differs from
		// the initial page, such as media included through an `include` macro.
		token: config?.mediaToken || recordedAuth.token,
	};
};

const getInitialEnforceDataSecurityPolicy = (): boolean => {
	if (!fg('dlp_attachment_download_respects_data_export_dsp')) return false;
	const shouldEnforceDataSecurityPolicy = mediaConfigEnforceDSPRecorder?.get();
	return !!shouldEnforceDataSecurityPolicy;
};

const getServerModeConfig = (config: AuthProviderConfigOptions): MediaSSR => ({
	mode: 'server' as const,
	config: {
		authProvider: () => Promise.reject(new Error(MEDIA_PROVIDER_UNAVAILABLE_ERROR)),
		initialAuth: getInitialAuth(config),
		enforceDataSecurityPolicy: getInitialEnforceDataSecurityPolicy(),
	},
});

const createClientAuthProvider =
	({ contentId, spaceKey, isExternalShare, mediaToken }: AuthProviderConfigOptions): AuthProvider =>
	async (authContext): Promise<MediaAuth> => {
		if (!!contentId && !!spaceKey) {
			const { viewMediaClientConfig } = await createRendererMediaProvider({
				contentId,
				spaceKey,
				isExternalShare,
				mediaToken,
			});
			return viewMediaClientConfig.authProvider(authContext);
		} else {
			throw new Error(MEDIA_PROVIDER_UNAVAILABLE_ERROR);
		}
	};

const getClientModeConfig = (config: AuthProviderConfigOptions): MediaSSR => ({
	mode: 'client' as const,
	config: {
		authProvider: createClientAuthProvider(config),
		initialAuth: window.__MEDIA_CONFIG__ as MediaAuth,
		enforceDataSecurityPolicy: window.__MEDIA_CONFIG_ENFORCE_DSP__ as boolean,
	},
});

export const useMediaSSRModeAndAuthConfig = (
	config: AuthProviderConfigOptions,
): MediaSSR | undefined => {
	if (isServerEnvironment()) {
		return getServerModeConfig(config);
	} else if (isClientEnvironment()) {
		return getClientModeConfig(config);
	}
};
