import React, { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl-next';

import {
	type ProfileCardGetActions,
	getProfileCardProvider,
} from '@confluence/fabric-media-support';
import { FeedFollow, useFeedFollow, getAtlassianPeopleProfileUrl } from '@confluence/profile';
import { CONTEXT_PATH } from '@confluence/named-routes';

type UseProfileCardProviderProps = {
	push: (url: string, forceReload?: boolean | undefined) => void;
	userId: string | null;
	cloudId: string;
	featureFlags: Record<string, string | boolean>;
};

export function useProfilecardProvider({
	push,
	userId,
	cloudId,
	featureFlags,
}: UseProfileCardProviderProps) {
	const intl = useIntl();
	const feedFollowData = useFeedFollow();

	const handleProfileCardActions: (...args: any) => ProfileCardGetActions = useCallback(
		(pushProfileCardActions, loggedInUserId) => (profileCardUserId) => {
			const atlassianPeopleProfileUrl = getAtlassianPeopleProfileUrl(
				profileCardUserId,
				null,
				CONTEXT_PATH,
			);

			const followed = !!(
				profileCardUserId && feedFollowData?.followedUsers?.includes(profileCardUserId)
			);
			return [
				{
					label: intl.formatMessage({ id: 'view.profile' }),
					callback: () => {
						pushProfileCardActions(
							getAtlassianPeopleProfileUrl(profileCardUserId, null, CONTEXT_PATH),
						);
					},
					link: atlassianPeopleProfileUrl,
				},
				{
					label: <FeedFollow followed={followed} />,
					id: 'feed-follow-user',
					callback: feedFollowData.getToggleFollowUser(profileCardUserId, followed, 'editor'),
					shouldRender: (userData?: { status: string }) =>
						feedFollowData.renderFeedFollow &&
						Boolean(profileCardUserId) &&
						profileCardUserId !== loggedInUserId &&
						userData?.status === 'active',
				},
			];
		},
		[feedFollowData, intl],
	);

	return useMemo(() => {
		return getProfileCardProvider(cloudId, handleProfileCardActions(push, userId), featureFlags);
	}, [cloudId, handleProfileCardActions, push, userId, featureFlags]);
}
