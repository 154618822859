import type { AnalyticsEventPayload } from '@atlaskit/analytics-next';

export type FeatureFlags = { [flag: string]: any };

export function extendPayloadAttributes(
	payload: AnalyticsEventPayload,
	newAttributes: Record<string, any>,
) {
	return {
		...payload,
		attributes: {
			...payload.attributes,
			...newAttributes,
		},
	};
}

export const getProseMirrorFMPTimestamp = (startTime, duration) => {
	return Number.isFinite(startTime) && Number.isFinite(duration)
		? Math.round(startTime + duration)
		: undefined;
};

/*
 * Extracts attributes from the context object and returns them as a single object.
 * This is used when you have multiple context objects with attributes and you want to merge them into a single object.
 */
export const extractAttributesFromContext = (context: Record<string, any>[]) => {
	return context
		.filter(
			(obj) =>
				obj.attributes && typeof obj.attributes === 'object' && !Array.isArray(obj.attributes),
		)
		.reduce((acc, obj) => ({ ...acc, ...obj.attributes }), {});
};
