import { token } from '@atlaskit/tokens';

import {
	hubElementPlaceholderExtensionKey,
	hubElementPlaceholderExtensionType,
} from '../hub-element-placeholder/hubElementPlaceholderConstants';

export const hubSectionExtensionType = 'com.atlassian.confluence.hub-section';
export const hubSectionExtensionKey = 'hub-section';
export const hubSectionAnalyticsSource = 'hub-sectionExtensionConfig';

export enum CONTENT_WIDTH {
	NARROW = 'narrow',
	WIDE = 'wide',
	FULL = 'full',
}
export const DEFAULT_CONTENT_WIDTH = CONTENT_WIDTH.WIDE;
export const DEFAULT_BACKGROUND_COLOR = token('elevation.surface');

export const getSectionExtensionContents = ({
	header,
	subHeader,
}: {
	header: string;
	subHeader: string;
}) => [
	{
		type: 'extensionFrame',
		content: [
			{
				type: 'heading',
				attrs: {
					level: 1,
					localId: null,
				},
				content: [
					{
						type: 'text',
						text: `${header}`,
					},
				],
			},
			{
				type: 'paragraph',
				attrs: {
					localId: null,
				},
				content: [
					{
						type: 'text',
						text: `${subHeader}`,
					},
				],
			},
			{
				type: 'paragraph',
				attrs: {
					localId: null,
				},
			},
			{
				type: 'extension',
				attrs: {
					extensionKey: hubElementPlaceholderExtensionKey,
					extensionType: hubElementPlaceholderExtensionType,
					parameters: {},
					text: null,
					layout: 'default',
					localId: null,
				},
			},
		],
	},
];
