import gql from 'graphql-tag';

export const PublicLinkMediaDownloadTokenQuery = gql`
	query PublicLinkMediaDownloadTokenQuery($publicLinkId: ID!) {
		publicLinkInformation(id: $publicLinkId) {
			id
			body {
				atlas_doc_format {
					mediaToken {
						token
					}
				}
			}
		}
		mediaConfiguration: publicLinkMediaConfiguration(id: $publicLinkId) {
			clientId
			fileStoreUrl
		}
	}
`;
